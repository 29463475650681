
  <template>
  <div>
    <div
      class="ancient-gem-content-8-title wow animate__fadeInUp"
      data-wow-duration="2s"
      data-wow-delay="0s"
    >
      <img
        src="@/assets/images/8.1.png"
        class="ancient-gem-content-8-title_bg"
      />
      <h3 class="ancient-gem-content-8-text">Roadmap</h3>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <img src="@/assets/images/next1.png" />
      <div id="header" class="ancient-gem-content-8-slide">
        <carousel
          ref="carousel"
          :per-page="itemSlide"
          :navigationEnabled="true"
          :navigationNextLabel="''"
          :navigationPrevLabel="''"
        >
          <slide>
            <div class="ancient-gem-content-8-slide-item">
              <img src="@/assets/images/8.2.png" />
              <h3 class="ancient-gem-content-8-slide-item_title">
                <span> Phase 1 </span>
                <br />
                Q4/2021
              </h3>
              <ul>
                <li>Strategize</li>
                <li>Connecting partners</li>
              </ul>
            </div>
          </slide>
          <slide>
            <div class="ancient-gem-content-8-slide-item">
              <img src="@/assets/images/8.2.png" />
              <h3 class="ancient-gem-content-8-slide-item_title">
                <span> Phase 2 </span>
                <br />
                Q1/2022
              </h3>
              <ul>
                <li>Marketing</li>
                <li>Release trailer</li>
                <li>ICO</li>
              </ul>
            </div>
          </slide>
          <slide>
            <div class="item ancient-gem-content-8-slide-item">
              <img src="@/assets/images/8.2.png" />
              <h3 class="ancient-gem-content-8-slide-item_title">
                <span> Phase 3 </span>
                <br />
                Q2/2022
              </h3>
              <ul>
                <li>Marketing ICO</li>
                <li>Airdrop</li>
                <li>Release testnet version</li>
                <li>Release the product</li>
              </ul>
            </div>
          </slide>
          <slide>
            <div class="item ancient-gem-content-8-slide-item">
              <img src="@/assets/images/8.2.png" />
              <h3 class="ancient-gem-content-8-slide-item_title">
                <span> Phase 4 </span>
                <br />
                Q3/2022
              </h3>
              <ul>
                <li>Beta Game</li>
                <li>Open item sales</li>
                <li>Create NFT market</li>
              </ul>
            </div>
          </slide>
        </carousel>
      </div>
      <img src="@/assets/images/next2.png" />
    </div>
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "Page8",
  data() {
    return {
      itemSlide: this.onChangeTotalSlide(),
    };
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler() {
      if (window.outerWidth > 1024) {
        this.itemSlide = 3;
      }
      if (window.outerWidth <= 1024 && window.outerWidth > 768) {
        this.itemSlide = 2;
      }
      if (window.outerWidth <= 768) {
        this.itemSlide = 1;
      }
    },
    onChangeTotalSlide() {
      if (window.outerWidth > 1024) {
        return 3;
      }
      if (window.outerWidth <= 1024 && window.outerWidth > 768) {
        return 2;
      }
      if (window.outerWidth <= 768) {
        return 1;
      }
    },
  },
  components: {
    Carousel,
    Slide,
  },
};
</script>
<style lang="scss" src="@/styles/Views.scss"></style>;
