<template>
  <div class="home">
    <Header />
    <Views />
    <Footer />
  </div>
</template>
<script>
import Header from "./layout/Header.vue";
import Footer from "./layout/Footer.vue";
import Views from "./views/Views.vue";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    Views,
  },
};
</script>
<style lang="scss" scoped>
.home {
  overflow-x: hidden;
}
</style>
