<template>
  <router-view />
</template>
<script>
import "animate.css";
import { WOW } from "wowjs";
export default {
  name: "App",
  mounted() {
    new WOW().init();
    setTimeout(() => {
      // window.scrollTo({ top: 0, behavior: "smooth" });
    }, 300);
  },
};
</script>
<style lang="scss">
@import "@/styles/custom.scss";
body {
  margin: 0;
}
@font-face {
  font-family: "Exo";
  src: local("Exo"), url(./assets/font/Exo-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Exo-bold";
  src: local("Exo-Black"), url(./assets/font/Exo-Black.ttf) format("truetype");
}
.app {
  overflow: hidden;
  font-family: Exo;
}
</style>
