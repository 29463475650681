<template>
  <div :class="['ancient-gem-header', shown ? 'h-100' : '']">
    <div class="d-flex ancient-gem-header-web">
      <img
        class="ancient-gem-header-logo"
        src="@/assets/images/logo.png"
        alt=""
        srcset=""
      />
      <div
        class="ancient-gem-header-item ancient-gem-header-item-active"
        @click="onScrollToId('home')"
        id="home-header"
      >
        HOME
      </div>
      <div
        class="ancient-gem-header-item"
        @click="onScrollToId('about')"
        id="about-header"
      >
        ABOUT
      </div>
      <div
        class="ancient-gem-header-item"
        @click="onScrollToId('land')"
        id="land-header"
      >
        LAND
      </div>
      <div
        class="ancient-gem-header-item"
        @click="onScrollToId('tnm')"
        id="hpl-header"
      >
        AG
      </div>
      <div
        class="ancient-gem-header-item"
        @click="onScrollToId('roadmap')"
        id="roadmap-header"
      >
        ROADMAP
      </div>
      <!-- <div
        class="ancient-gem-header-item"
        @click="onScrollToId('ADVISORS')"
        id="ADVISORS-header"
      >
        ADVISORS
      </div> -->
      <div
        class="ancient-gem-header-item"
        @click="onScrollToId('PARTNERS')"
        id="PARTNERS-header"
      >
        PARTNERS
      </div>
      <div class="ancient-gem-header-item"><a href="/ANCIENT-GEM.pdf">PITCH DECK</a></div>
    </div>
    <div :class="['ancient-gem-header-mobile', shown ? 'bg-nav' : '']">
      <img class="ancient-gem-header-logo" src="@/assets/images/logo.png" />
      <div class="m-l-auto">
        <img
          v-show="!shown"
          @click="showMenu()"
          src="@/assets/images/nav.png"
        />
        <img
          v-show="shown"
          @click="showMenu()"
          src="@/assets/images/close.png"
        />
      </div>
    </div>
    <div :class="[shown ? 'menu-mobile' : 'd-none']">
      <ul :class="[shown ? 'd-block mb-0' : 'd-none']">
        <li>
          <div
            class="ancient-gem-header-item ancient-gem-header-item-active"
            @click="onScrollToId('home')"
            id="home-header"
          >
            HOME
          </div>
        </li>
        <li>
          <div
            class="ancient-gem-header-item"
            @click="onScrollToId('about')"
            id="about-header"
          >
            ABOUT
          </div>
        </li>
        <li>
          <div
            class="ancient-gem-header-item"
            @click="onScrollToId('land')"
            id="land-header"
          >
            LAND
          </div>
        </li>
        <li>
          <div
            class="ancient-gem-header-item"
            @click="onScrollToId('tnm')"
            id="agem-header"
          >
            AG
          </div>
        </li>
        <li>
          <div
            class="ancient-gem-header-item"
            @click="onScrollToId('roadmap')"
            id="roadmap-header"
          >
            ROADMAP
          </div>
        </li>
        <!-- <li>
          <div
            class="ancient-gem-header-item"
            @click="onScrollToId('ADVISORS')"
            id="ADVISORS-header"
          >
            ADVISORS
          </div>
        </li> -->
        <li>
          <div
            class="ancient-gem-header-item"
            @click="onScrollToId('PARTNERS')"
            id="PARTNERS-header"
          >
            PARTNERS
          </div>
        </li>
        <li>
          <div class="ancient-gem-header-item"><a href="/ANCIENT-GEM.pdf">PITCH DECK</a></div>
        </li>
      </ul>
    </div>
  </div>
</template>
<style lang="scss" src="@/styles/Header.scss"></style>;
<script>
export default {
  name: "Header",
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      shown: false,
    };
  },
  methods: {
    showMenu() {
      this.shown = !this.shown;
    },
    onScrollToId(id) {
      this.shown = false;
      setTimeout(() => {
        const yOffset = -100;
        const element = document.getElementById(id);
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }, 300);
    },
    handleScroll() {
      for (
        let ix = 0;
        ix <
        document.getElementsByClassName("ancient-gem-content-scroll").length;
        ix++
      ) {
        const element = document.getElementsByClassName(
          "ancient-gem-content-scroll"
        )[ix];
       
        const bound = document
          .getElementById(element.id)
          .getBoundingClientRect();
        if (
          bound.bottom > 100 &&
          bound.top - bound.height <= 0 &&
          bound.bottom <= bound.height + 100
        ) {
          document
            .getElementById(element.id + "-header")
            .classList.add("ancient-gem-header-item-active");
        } else {
          document
            .getElementById(element.id + "-header")
            .classList.remove("ancient-gem-header-item-active");
        }
      }
    },
  },
};
</script>
