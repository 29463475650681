<template>
  <div class="ancient-gem-body">
    <div
      id="home"
      class="
        ancient-gem-content-scroll ancient-gem-content ancient-gem-content-1
      "
    >
      <div class="ancient-gem-content-1_group">
        <div class="ancient-gem-content-1_item">
          <div
            class="
              ancient-gem-content-1_left
              animate__animated
              wow
              animate__backInLeft
            "
            data-wow-duration="1s"
            data-wow-delay="1s"
          >
            <img
              class="ancient-gem-content-1_left_top"
              src="@/assets/images/page-1-top.png"
            />
            <img
              class="ancient-gem-content-1_left_bottom"
              src="@/assets/images/page-1-bottom.png"
            />
          </div>
        </div>
        <div
          class="
            animate__animated
            wow
            animate__backInRight
            ancient-gem-content-1_item
          "
          data-wow-duration="1s"
          data-wow-delay="1s"
        >
          <div class="ancient-gem-content-1_image">
            <div class="ancient-gem-content-1_video">
              <img
                src="@/assets/images/1.1.png"
                class="ancient-gem-content-1_video_bg"
              />
              <div class="ancient-gem-content-1_video_content">
                <img
                  class="ancient-gem-content-1_video_content_bg"
                  src="@/assets/images/video1.png"
                />
                <img
                  class="ancient-gem-content-1_video_content-play"
                  src="@/assets/images/play.png"
                />
              </div>
            </div>
            <div class="ancient-gem-content-1_button">
              <div class="pos-relative">
                  <a href="http://buy.theancientgem.com/user/login">
                      <img class="cursor-pointer" src="@/assets/images/button.png"/>
                      <div class="pos-absolute w-100 text-align-center top-20 text">
                          Login
                      </div>
                  </a>
              </div>
              <div class="pos-relative">
                <a href="http://buy.theancientgem.com/user/register">
                <img class="cursor-pointer" src="@/assets/images/button.png" />
                <div class="pos-absolute w-100 text-align-center top-20 text">
                  Register
                </div>
                </a>
              </div>
            </div>
            <div class="ancient-gem-content-1_soft-hard-cap">
              <div class="title-timeout">Private-Sale 1st will start</div>
              <div class="button-buy-now" v-if="hours == 0 && minutes == 0 && seconds == 0">
                <h2 style="text-align: center">
                  Started
                </h2>
              </div>
              <div class="d-flex" v-else>
                <div class="item-time">
                  <div class="fsz-20">
                    {{ days | coverNumberToTime }}
                  </div>
                  days
                </div>
                :
                <div class="item-time">
                  <div class="fsz-20">{{ hours | coverNumberToTime }}</div>
                  hours
                </div>
                :
                <div class="item-time">
                  <div class="fsz-20">{{ minutes | coverNumberToTime }}</div>
                  minutes
                </div>
                :
                <div class="item-time">
                  <div class="fsz-20">{{ seconds | coverNumberToTime }}</div>
                  seconds
                </div>
              </div>

              <div class="d-flex m-t-8">
                <div class="fsz-20">SoftCap {{ softCap }} USD</div>
                <div class="fsz-20">HardCap {{ hardCap }} USD</div>
              </div>
              <div class="progress m-t-8">
                <div
                  class="progress-bar"
                  role="progressbar"
                  v-bind:style="{ width: percentCap + '%' }"
                  aria-valuenow="percentCap"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  ~{{ percentCap | coverNumberToTime }}%
                </div>
              </div>
              <div class="button-buy-now">
                <button>Buy now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="about"
      class="
        ancient-gem-content-scroll ancient-gem-content ancient-gem-content-2
      "
    >
      <div class="container">
        <div class="ancient-gem-content-2_group">
          <div
            class="
              ancient-gem-content-2_image
              animate__animated
              wow
              animate__backInLeft
            "
            data-wow-duration="1s"
            data-wow-delay="1s"
          >
            <img src="@/assets/images/2.1.png" />
            <h2 class="ancient-gem-content-2_image_title-1">
              Become a Landlord
            </h2>
            <p class="ancient-gem-content-2_image_content-1">
              To own a prosperous and full of potential land – a rare and
              valuable gem, it will take you a considerable price. Ancient Gem
              will help you achieve that dream of yours so you can invest your
              creativity into. You can become a landlord, a magnificent
              investor, a creative businessperson, a potential designer, or just
              simply turn your dream life into lively sceneries.
            </p>
          </div>
          <div
            class="
              ancient-gem-content-2_image
              animate__animated
              wow
              animate__backInDown
            "
            data-wow-duration="1s"
            data-wow-delay="1s"
          >
            <img src="@/assets/images/2.1.png" />
            <h2 class="ancient-gem-content-2_image_title-2">Metaverse</h2>
            <p class="ancient-gem-content-2_image_content-2">
              The land system will be actualized with lively 3D images. The
              enormous land untouched by human yours to invest into. You can
              build up Mall centers, Entertainment centers, play grounds.
            </p>
          </div>
          <div
            class="
              ancient-gem-content-2_image
              animate__animated
              wow
              animate__backInRight
            "
            data-wow-duration="1s"
            data-wow-delay="1s"
          >
            <img src="@/assets/images/2.1.png" />
            <h2 class="ancient-gem-content-2_image_title-3">Farm to earn</h2>
            <p class="ancient-gem-content-2_image_content-3">
              By having yourself a prosperous land at the countryside, you will
              become a true farm owner. All the farming becomes easier and more
              unique, and especially, it will result in ownership of the one and
              only NFT token.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="land" class="ancient-gem-content-scroll">
      <div class="ancient-gem-content ancient-gem-content-3">
        <div
          class="container animate__animated wow animate__zoomIn"
          data-wow-duration="1s"
          data-wow-delay="1s"
        >
          <div class="ancient-gem-content-3_box">
            <p class="ancient-gem-content-3_box_content">
              Inspired by the commercial city of SINGAPORE, where the land is
              small in area but prosperous for all business people. You can own
              8-star hotels or do business at the ports.
              <br />
              <br />
              The AG is a virtual place where people can have fun, build, own
              and earn money through their virtual experience. We give artists,
              creators and players a platform they have always wanted, with the
              means to do whatever they desire.
            </p>
          </div>
        </div>
      </div>
      <div class="ancient-gem-content ancient-gem-content-4">
        <div
          class="container animate__animated wow animate__zoomIn"
          data-wow-duration="1s"
          data-wow-delay="1s"
        >
          <div class="ancient-gem-content-4_box">
            <h2 class="ancient-gem-content-4_box_title">Market</h2>
            <p class="ancient-gem-content-4_box_content">
              Where all the items earned by the hard work and creativity of
              artists, landlords are exchanged.
            </p>
          </div>
        </div>
      </div>
      <div class="ancient-gem-content ancient-gem-content-5">
        <div
          class="container animate__animated wow animate__zoomIn"
          data-wow-duration="1s"
          data-wow-delay="1s"
        >
          <div class="ancient-gem-content-5_box">
            <h2 class="ancient-gem-content-5_box_title">
              To own these precious lands
            </h2>
            <p class="ancient-gem-content-5_box_content">
              To become a landlord at Ancient Gem, players need to create an
              account and link to Metamask wallet. Player data will be stored in
              Ancient Gem system. At Ancient Gem, players will become landlords
              to do business, making their own NFTs.
            </p>
          </div>
        </div>
      </div>
      <div class="ancient-gem-content ancient-gem-content-6">
        <div
          class="container animate__animated wow animate__zoomIn"
          data-wow-duration="1s"
          data-wow-delay="1s"
        >
          <h2 class="ancient-gem-content-6_image_title">Videos of gameplay</h2>
          <div class="ancient-gem-content-6_image">
            <div class="ancient-gem-content-6_video">
              <img
                src="@/assets/images/6.1.png"
                class="ancient-gem-content-6_video_bg"
              />
              <div class="ancient-gem-content-6_video_content">
                <img
                  class="ancient-gem-content-6_video_content-1"
                  src="@/assets/images/video1.png"
                />
                <img
                  class="ancient-gem-content-6_video_content-play"
                  src="@/assets/images/play.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="hpl"
      class="
        ancient-gem-content-scroll ancient-gem-content ancient-gem-content-7
      "
    >
      <div class="container">
        <div class="ancient-gem-content-7_group">
          <div
            class="
              animate__animated
              wow
              animate__backInLeft
              ancient-gem-content-7_item
            "
            data-wow-duration="1s"
            data-wow-delay="1s"
          >
            <div class="ancient-gem-content-7_left">
              <img
                class="ancient-gem-content-7_left-image"
                src="@/assets/images/7.1.png"
              />
              <h2 class="ancient-gem-content-7_left_title">Tokenomics</h2>
              <div class="ancient-gem-content-7_left_content">
                <h3 class="ancient-gem-content-7_left_content-title">
                  Total supply: 7.000.000.000 AG
                </h3>
                <p class="ancient-gem-content-7_left_content-text">
                  - Play-to-earn: 28%
                  <br />
                  - Private sale: 9,9%
                  <br />
                  - Public sale : 1,5%
                  <br />
                  - Team: 12,5%
                  <br />
                  - Marketing and partnership: 32,1%
                  <br />
                  - Liquidity : 1,5%
                  <br />
                  - Ecosystem reserve: 5%
                  <br />
                  - Staking rewards : 6%
                  <br />
                  - Advisory: 3,5%
                </p>
              </div>
            </div>
          </div>
          <div
            class="
              animate__animated
              wow
              animate__backInRight
              ancient-gem-content-7_item
            "
            data-wow-duration="1s"
            data-wow-delay="1s"
          >
            <div class="ancient-gem-content-7_right">
              <img
                class="ancient-gem-content-7_right-image"
                src="@/assets/images/7.2.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="roadmap"
      class="
        ancient-gem-content-scroll ancient-gem-content ancient-gem-content-8
      "
    >
      <Page8 />
    </div>
    <!-- <div
      id="ADVISORS"
      class="
        ancient-gem-content-scroll ancient-gem-content ancient-gem-content-9
      "
    >
      <div
        class="
          ancient-gem-content-9_content
          animate__animated
          wow
          animate__backInDown
        "
        data-wow-duration="1s"
        data-wow-delay="1s"
      >
        <div class="ancient-gem-content-9_content_image">
          <img src="@/assets/images/9.2.png" />
          <h3 class="ancient-gem-content-9_content_image_title">Adivisors</h3>
        </div>
        <div class="ancient-gem-content-9_content_adivisors">
          <div class="ancient-gem-content-9_content_adivisors_group">
            <div class="ancient-gem-content-9_content_adivisors_item">
              <div class="ancient-gem-content-9_content_item1">
                <div class="ancient-gem-content-9_content_item1-top">
                  <div class="name">JOhn wick</div>
                  <img
                    class="ancient-gem-content-9_content_item1-top-bg"
                    src="@/assets/images/9.1.png"
                  />
                  <img
                    class="ancient-gem-content-9_content_item1-top-image"
                    src="@/assets/images/Star_1.png"
                  />
                </div>
                <div class="ancient-gem-content-9_content_item1-bottom">
                  <ul>
                    <li>15 years of experience in business marketing</li>
                    <li>
                      7 years of experience in marketing virtual currencies
                    </li>
                    <li>Business marketing for South Korean government</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="ancient-gem-content-9_content_adivisors_item">
              <div class="ancient-gem-content-9_content_item2">
                <div class="ancient-gem-content-9_content_item2-top">
                  <div class="name">JOhn wick</div>
                  <img
                    class="ancient-gem-content-9_content_item2-top-bg"
                    src="@/assets/images/9.3.png"
                  />
                  <img
                    class="ancient-gem-content-9_content_item2-top-image"
                    src="@/assets/images/Star_2.png"
                  />
                </div>
                <div class="ancient-gem-content-9_content_item2-bottom">
                  <ul>
                    <li>15 years of experience in business marketing</li>
                    <li>
                      7 years of experience in marketing virtual currencies
                    </li>
                    <li>Business marketing for South Korean government</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="ancient-gem-content-9_content_adivisors_item">
              <div class="ancient-gem-content-9_content_item3">
                <div class="ancient-gem-content-9_content_item3-top">
                  <div class="name">JOhn wick</div>
                  <img
                    class="ancient-gem-content-9_content_item3-top-bg"
                    src="@/assets/images/9.4.png"
                  />
                  <img
                    class="ancient-gem-content-9_content_item3-top-image"
                    src="@/assets/images/Star_3.png"
                  />
                </div>
                <div class="ancient-gem-content-9_content_item3-bottom">
                  <ul>
                    <li>15 years of experience in business marketing</li>
                    <li>
                      7 years of experience in marketing virtual currencies
                    </li>
                    <li>Business marketing for South Korean government</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div
      id="PARTNERS"
      class="
        ancient-gem-content-scroll ancient-gem-content ancient-gem-content-10
      "
    >
      <div class="container">
        <div class="ancient-gem-content-10_content">
          <img
            class="ancient-gem-content-10_content_bg"
            src="@/assets/images/10.1.png"
          />
          <h3 class="ancient-gem-content-10_content-title">Partners</h3>
          <div class="ancient-gem-content-10_content-item">
            <img
              src="@/assets/images/ara.jpeg"
              class="animate__animated wow animate__backInRight"
              style="width: 150px;
    height: 104px;"
              data-wow-duration="1s"
              data-wow-delay="1s"
            />
            <img
              src="@/assets/images/bthum.jpeg"
              class="animate__animated wow animate__backInDown"
              data-wow-duration="1s"
              data-wow-delay="1s"
              style="width: 150px;
    height: 104px;"
            />
            <img
              src="@/assets/images/matrix.jpeg"
              class="animate__animated wow animate__backInDown"
              data-wow-duration="1s"
              data-wow-delay="1s"
              style="width: 150px;
    height: 104px;"
            />
            <img
              src="@/assets/images/kucoin.jpeg"
              class="animate__animated wow animate__backInLeft"
              data-wow-duration="1s"
              data-wow-delay="1s"
              style="width: 150px;
    height: 104px;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" src="@/styles/Views.scss"></style>;
<script>
import Page8 from "./Page8.vue";
export default {
  name: "Page1",
  components: {
    Page8,
  },
  data() {
    return {
      timeOut: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      softCap: 1000000,
      hardCap: 2000000,
      percentCap: 0,
    };
  },
  created() {
    // var now = new Date;
    // // var utcnow = new Date().toUTCString();
    // var t1 = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate() ,
    //         now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
    // var t2 = Date.UTC(2022,3,25,8,0,0);
    // this.timeOut = Math.round(( t2 - t1) / 1000);
    // this.onChangeTotalSlide();
    this.percentCap = (this.softCap / this.hardCap) * 100;
  },
  methods: {
    onChangeTotalSlide() {
      setInterval(() => {
        this.timeOut = this.timeOut - 1;
        this.days = Math.floor(this.timeOut / 86400);
        this.hours = Math.floor((this.timeOut - this.days * 86400) / 3600);
        this.minutes = Math.floor(
          (this.timeOut - this.days * 86400 - this.hours * 3600) / 60
        );

        this.seconds =
          this.timeOut -
          this.days * 86400 -
          this.hours * 3600 -
          this.minutes * 60;
      }, 1000);
    },
  },
  filters: {
    coverNumberToTime: function (value) {
      if (value <= 0) return "00";
      if (value < 10) return "0" + value;
      return value;
    },
  },
};
</script>
