<template>
    <div class="ancient-gem-footer">
        <div class="ancient-gem-footer-sub">
            <img
                    class="
          animate__animated
          wow
          animate__bounceInDown
          ancient-gem-footer-logo
        "
                    data-wow-duration="0.5s"
                    data-wow-delay="0.5s"
                    src="@/assets/images/logo.png"
            />
            <div class="ancient-gem-footer-content">
                <div class="d-flex w-50 ancient-gem-footer-content-sub">
                    <div class="w-50">
                        <div
                                class="animate__animated wow animate__bounceIn text-footer"
                                data-wow-duration="0.5s"
                                data-wow-delay="0.5s"
                        >
                            About Us
                        </div>
                        <div
                                class="animate__animated wow animate__bounceIn text-footer"
                                data-wow-duration="0.5s"
                                data-wow-delay="0.5s"
                        >
                            NFT Marketplace
                        </div>
                        <div
                                class="animate__animated wow animate__bounceIn text-footer m-b-0"
                                data-wow-duration="0.5s"
                                data-wow-delay="0.5s"
                        >
                            Roadmap
                        </div>
                    </div>
                    <div class="w-50">
                        <div
                                class="animate__animated wow animate__bounceIn text-footer"
                                data-wow-duration="0.5s"
                                data-wow-delay="0.5s"
                        >
                            Tokenomics
                        </div>
                        <div
                                class="animate__animated wow animate__bounceIn text-footer"
                                data-wow-duration="0.5s"
                                data-wow-delay="0.5s"
                        >
                            FAQs
                        </div>
                        <div
                                class="animate__animated wow animate__bounceIn text-footer m-b-0"
                                data-wow-duration="0.5s"
                                data-wow-delay="0.5s"
                        >
                            Partners
                        </div>
                    </div>
                </div>
                <div class="w-50 ancient-gem-footer-content-sub_2">
                    <div
                            class="animate__animated wow animate__bounceIn text-footer"
                            data-wow-duration="0.5s"
                            data-wow-delay="0.5s"
                    >
                        Social Media
                    </div>
                    <div class="group-icon-footer">
                        <a href="https://twitter.com/Ancient_Gem">
                            <img
                                    src="@/assets/images/logo-footer/twitter.png"
                                    class="animate__animated wow animate__bounceIn"
                                    data-wow-duration="0.5s"
                                    data-wow-delay="0.5s"
                            />
                        </a>
                        <a href="https://t.me/Ancientgem">
                            <img
                                    src="@/assets/images/logo-footer/telegram.png"
                                    class="animate__animated wow animate__bounceIn"
                                    data-wow-duration="0.5s"
                                    data-wow-delay="0.5s"
                            />
                        </a>
                        <a href="mailto:Ancientgem2021@gmail.com">
                            <img
                                    src="@/assets/images/logo-footer/gmail.png"
                                    class="animate__animated wow animate__bounceIn"
                                    data-wow-duration="0.5s"
                                    data-wow-delay="0.5s"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div class="m-t-30 m-b-0 text-footer">@copyright 2021 by Ancient Gem</div>
        </div>
    </div>
</template>
<style lang="scss" src="@/styles/Footer.scss"></style>;
<script>
    export default {
        name: "Footer",
    };
</script>
